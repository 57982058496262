import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import CaseStudiesDetailsContent from '../../components/case-studies/tms/CaseStudiesDetailsContent'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Sistem de management în transport"
                homePageText="Studii de caz"
                homePageUrl="/studii-de-caz/"
                activePageText="Sistem de management în transport"
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}

export default CaseDetails