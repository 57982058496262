import React from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../../assets/images/projects/projects-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Software la comandă</span>
                            <h3>Compania</h3>
                            <p>O companie de transport de mărfuri din Europa Centrală și de Sud-Est, cu un parc auto
                                considerabil, format din mașini de mare tonaj, ne-a solicitat sprijinul în realizarea
                                unei soluții web personalizate. Aflată în continuă dezvoltare, compania a conștientizat
                                nevoia acestei soluții care să le ofere posibilitatea de a urmări locația camioanelor
                                lor în timp real și de a avea funcționalități în planificarea și programarea rutelor,
                                urmărirea încărcăturii și gestionarea șoferilor.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="image">
                                        <img src={project2} alt="about"/>
                                    </div>
                                </div>
                                <h3>Provocarea</h3>
                                <p>Ne-a plăcut provocarea aceasta, mai ales că <strong>Dan Pop</strong>, colegul nostru a activat ani de zile
                                    ca manager de transport în companii de profil, așa că ne-am simțit ”acasă” din toate punctele
                                    de vedere.</p>
                                <p>După ce am analizat în detaliu împreună cu reprezentanții companiei toate cerințele
                                    și necesitățile, am creionat caietul de sarcini și am concluzionat împreună că este nevoie de
                                    o aplicație web elaborată, care în final să ajute, evident, la reducerea costurilor de combustibil
                                    și să îmbunătățească timpul de livrare. De asemenea, funcțiile de planificare și programare ale
                                    rutelor să-i ajute să își gestioneze bine flota și să se asigure că mașinile lor de mare tonaj
                                    lor sunt utilizate eficient.</p>
                                <div className="col-lg-12 col-md-12">
                                    <div className="content">
                                        <h3>Soluția</h3>
                                        <p>Am înțeles că trebuie să dezvoltăm un sistem care să ofere precizie în organizare și
                                            simplificarea managementului companiei pentru mai multe departamente: parc auto, 
                                            resurse umane, vânzări, financiar, operațional.</p> 
                                        <p>Pe baza acestor informații, <strong>Vertical Digital</strong> a început să dezvolte o aplicație web 
                                            care să răspundă nevoilor companiei de transport. Am folosit o combinație de:</p>
                                        <ul>
                                            <li>HTML</li>
                                            <li>CSS și JavaScript pentru front-end</li>
                                            <li>Node.js pentru back-end</li>  
                                        </ul>
                                    </div>
                                        <p>Ne-am asigurat ca aplicația să aibă și un design responsive, astfel încât să poată fi accesată
                                            atât pe desktop, cât și pe dispozitive mobile și să funcționeze la fel de rapid și eficient
                                            de oriunde este folosită de utilizatori.</p>
                                        <p>Pentru realizarea acestui proiect am avut nevoie de patru luni de muncă susținută în care am
                                            implicat o echipă de cinci ingineri:</p>
                                    <div className="content">
                                        <ul>
                                            <li>2 ingineri pentru back-end</li>
                                            <li>2 ingineri pe front-end (interfața)</li>
                                            <li>1 inginer pe asigurarea calității</li>  
                                        </ul>
                                    </div>
                                        <p>După patru luni, clientul nostru s-a bucurat de control deplin centralizat asupra întregii
                                            activități. Prin urmare, în aplicația creată de Vertical Digital comapania are acces la:</p>
                                    <div className="content">
                                        <ul>
                                            <li>Evidența asupra întregului parc auto: situație ITP, RCS, viniete, revizii, service,
                                                anvelope etc</li>
                                            <li>Evidența resurselor umane, respectiv statusul șoferilor de camion: valabilitatea
                                                documentelor, concedii, atestate, perioada petrecută în delegații etc</li>
                                            <li>Evidența clienți și terți (alte companii de transport către care compania  subcontractează),
                                                lista neagră (clienți indezirabili, cu care preferă să nu mai colaboreze, din anumite motive)</li>  
                                            <li>Managementul comenzilor</li>
                                            <li>Raportări pe toate situațiile financiare in timp real atât la nivel general de companie
                                                cât și per client sau vehicul</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            

                            <h3>Rezultate</h3>
                            <p>După ce am finalizat aplicația web, am testat-o și verificat-o în cele mai mici detalii, pentru a ne
                                asigura că nu dă erori și că funcționează precis, conform așteptărilor. În plus, am avut mai multe
                                ședințe de training cu angajații companiei de transport, pentru a-i familiariza cu privire la modul
                                de utilizare al aplicației.</p>
                            <p>De la implementarea soluției create de Vertical Digital, compania de camioane a înregistrat o creștere
                                semnificativă a eficienței și productivității. Acum își pot urmări camioanele în timp real, ceea ce 
                                i-a ajutat să reducă costurile cu combustibilul și să îmbunătățească timpul de livrare. Funcțiile de 
                                planificare și programare a rutei au ajutat de asemenea să își gestioneze mult mai ușor flota și să 
                                se asigure că toate camioanele lor sunt utilizate eficient. Mai mult, comunicarea dintre departamentele
                                companiei, indiferent că erau de natură operațională, de management sau financiare s-a îmbunătățit
                                considerabil, ceea ce a dus la timpi de reacție mult mai rapizi din toate părțile implicate.</p>
                            <p>Prin urmare, aplicația web dezvoltată de Vertical Digital a fost un instrument valoros pentru compania
                                de camioane, care a ajutat mult la creșterea profitului.</p>

                            <h3>Opinii</h3>
                            <p>”Ne-a plăcut mult această colaborare. Colegul meu Dan Pop are expertiză de management în domeniul
                                transporturilor, așa că am înțeles mult mai bine nevoile clientului și am reușit să le punem în
                                aplicare, fix cum și-a dorit. Cu ajutorul echipei noastre de ingineri specializați în aplicații
                                web am creat această aplicație care i-a adus clientului beneficii financiare, dar și în munca de
                                zi cu zi.”</p>
                            <p>
                            Alex Gheboianu, Vertical Digital
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent